import React, {useEffect, memo, useRef} from 'react';
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import Moment from 'moment';
import { useForm, Controller, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import NokForm from '../../components/forms/kin/NokForm';
import ContactInfo from '../../components/forms/contactInfo/ContactInfo'
import { genderOptions, ethnicitiesOptions, 
    boroughs, languagesSpokenOptions, pronuonOptions } from '../../assets/util/selectOptions'
import { messageResources } from '../../assets/util/messageResources'
import { addStudentSchema, editStudentSchema } from '../../assets/util/yupValidations'
import axios from 'axios'
import useAuth from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import addLocation from "../../assets/util/addLocation"
import formatData from "../../assets/util/formatData"
import noProfile from '../../assets/img/noProfile/noProfile.jpg'
import numberDaysDifference from "../../assets/util/numberDaysDifference"
import Tab from 'react-bootstrap/Tab'
import Toggle from 'react-toggle'
import Tabs from 'react-bootstrap/Tabs'
import { useAddNewStudentMutation, 
    useUpdateStudentMutation,
    useUpdateGooDocumentFromStudentMutation,
    useAddGooDocumentFromStudentMutation } from '../../redux/student/studentSlice';
import { useUpdateNotificationMutation, 
    selectAllNotifications } from "../../redux/notifications/notificationsApiCalls";


const StudentForm = ({studentData, isEdit}) => {

    //console.log("|-----> StudentForm COMPONENT StudentForm <----|")

    // DEMO WITH NEW SLICES
    const [ addNewStudent, { isLoading }] = useAddNewStudentMutation()
    const [ updateStudent ] = useUpdateStudentMutation()
    const [updateGooDocumentFromStudent] = useUpdateGooDocumentFromStudentMutation()
    const [addGooDocumentFromStudent] = useAddGooDocumentFromStudentMutation()
    const [updateNotification] = useUpdateNotificationMutation()

    function SortArray(x, y){
        return x.label.localeCompare(y.label);
    }

    const boroughsSorted = boroughs.sort(SortArray);
    const ethnicitiesSorted = ethnicitiesOptions.sort(SortArray);

    const notis = useSelector(selectAllNotifications)
    
    const {id} = useAuth()
    const navigate = useNavigate();

    let btnRef = useRef();
    let buttonsInfo = [{}]

    const methods = useForm({
        //defaultValues: studentData,
        resolver: isEdit ? yupResolver(editStudentSchema) : yupResolver(addStudentSchema),
    });

    if(isEdit){
        buttonsInfo = [
            {title: `${messageResources.BUTTON.PROFILE}`, linkTo: `/students/${studentData?._id}`}
        ]
    }else{
        buttonsInfo = null
    }

    //console.log(buttonsInfo)

	const {
        register,
        handleSubmit,
        reset,
        setValue,
        defaultValues,
		control,
        formState: { errors },
    } = methods;


	useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        reset(studentData);
    }, [studentData]);

    console.log(errors)

	const onSubmit = (formData) => {

        console.log("NO ERRORS")

        //console.log("------> IS EDIT <---------")
        //console.log(formData)

        if(btnRef.current){
            //console.log("------> DISABLE BUTTON <---------")
            btnRef.current.setAttribute("disabled", "disabled");
        }
        
        formData.contact_number = formatData("phone",formData.contact_number)
        formData.nin = formatData("nin",formData.nin)

        if(!isEdit) formData.nokContact_number = formatData("phone",formData.nokContact_number)

		try{

			getLatLonPic(formData);

		}catch(err){
			console.log(err);
		}
	}

    

    const getLatLonPic = async (formData) => {

        const resFromMaps = await axios.get(`
            https://api.opencagedata.com/geocode/v1/json?q=${formData.address.address1} ${formData.address.address2} ${formData.address.postcode} London United Kingdom&key=c0348377c7af49c29b5b501efa40474b
        `)
        
        //console.log("------> RES FROM MAPS <---------")
        //console.log(resFromMaps?.data);
        // console.log(resFromMaps?.data.results[0].geometry.lat)
        // console.log(resFromMaps?.data.results[0].geometry.lng)


        formData.address.lat = resFromMaps?.data.results[0].geometry.lat;
        formData.address.long = resFromMaps?.data.results[0].geometry.lng
        //formData.address.city = "London";

        resFromMaps?.data.results[0].components?.town 
                            ? formData.address.city = resFromMaps?.data.results[0].components?.town
                            : resFromMaps?.data.results[0].components?.city 
                                ? formData.address.city = resFromMaps?.data.results[0].components?.city
                                : formData.address.city = ""
        
        if(!isEdit){
            const resFromMapsNok = await axios.get(`
                https://api.opencagedata.com/geocode/v1/json?q=${formData.nokAddress.address1} ${formData.nokAddress.address2} ${formData.nokAddress.postcode} United Kingdom&key=c0348377c7af49c29b5b501efa40474b
            `)

            formData.nokAddress.lat = resFromMapsNok?.data.results[0].geometry.lat;
            formData.nokAddress.long = resFromMapsNok?.data.results[0].geometry.lng
            resFromMapsNok?.data.results[0].components?.town 
                            ? formData.nokAddress.city = resFromMapsNok?.data.results[0].components?.town
                            : formData.nokAddress.city = ""
        }

        if(formData.picture[0]){ 

            //console.log("<><><><><><> UPLOADING PICTURE  <><><><><><>")
            // DATA FOR CLOUDINARY
            const pictureData = new FormData()
            pictureData.append("file",formData.picture[0])
            // uload preset name set in clooudinary
            pictureData.append("upload_preset","roots-pic")
            // given when signing up with cloudinary
            pictureData.append("cloud_name","dlkpi3swo")
            const  resFromCloudinary = await axios.post("https://api.cloudinary.com/v1_1/dlkpi3swo/image/upload",pictureData);
            //console.log(resFromCloudinary);
            //console.log(resFromCloudinary.data.url);
            formData.picture_path = resFromCloudinary.data.url;

        }

        //console.log("------> FROM AFTER MAPS <---------")
        //console.log(formData)
        studentAction(formData);

    }

    const studentAction = async (formData) => {

        formData.stuId = formData._id
        formData.stuName = `${formData?.name} ${formData?.surname}`
        await addLocation(formData) 

		//console.log("<><><><><><> studentAction studentAction studentAction studentAction <><><><><><>")
        const today = new Date()

        if(formData.passExpi) formData.passExpi = Moment(formData.passExpi).format('DD MMMM YYYY 15:00:00')

        if(formData.dob) {

            //console.log(formData.dob)
            const userInputDob = new Date(formData.dob)
            //console.log(userInputDob)
             //calculate month difference from current date in time  
            var month_diff = Date.now() - userInputDob.getTime();  
            //console.log(month_diff)
            //convert the calculated difference in date format  
            var age_dt = new Date(month_diff); 
            //console.log(age_dt)  
            //extract year from date      
            var year = age_dt.getUTCFullYear();  
            //console.log(year)
            //now calculate the age of the user  
            var age = Math.abs(year - 1970);  
            //console.log(age)
    
            formData.age = age

             // THE SERVER RECORDS THE DAY ONE HOUR EARLIER AS IT IS UTC and UK is UTC+1
            // IF WE DON'T FORMAT LIKE THIS THE DATE STORED WILL BE
            // ONE DAY BEFORE AT 23:00
            // WE FORMAT TO ADD A TIME IN THE MIDDLE OF THE DAY TO
            // AVOID THE SERVER STORING ONE DAY BEFORE
            formData.dob = Moment(formData.dob).format('DD MMMM YYYY 15:00:00')
            
        }
        

        try{

            const today = new Date()
        
            notis?.map(una => {

                if( !una.isProcessed && una.studentId._id == studentData.id){

                    
                    if(una.type == 6 
                        && formData.nin){
                    
                        let notiBody = {}
                        notiBody.idFor = una.id
                        notiBody.isProcessed = true
                        notiBody.processedBy = id
                        notiBody.dateProcessed = today
                        notiBody.daysElapsed = numberDaysDifference(una.createdAt,today)

                        updateNotification(notiBody).unwrap()
                    
                    }

                    if(una.type == 1
                        && formData.passExpi
                        && new Date(formData.passExpi).getTime() > today.getTime()){
                    
                        let notiBody = {}
                        notiBody.idFor = una.id
                        notiBody.isProcessed = true
                        notiBody.processedBy = id
                        notiBody.dateProcessed = today
                        notiBody.daysElapsed = numberDaysDifference(una.createdAt,today)

                        updateNotification(notiBody).unwrap()
                        
                    }
                }
                
            })

            if(!formData.gooDocuments){
                formData.gooDocuments = []
            }

        
            if(formData.cert_path){

                let addNew = true;
                isEdit && studentData.gooDocuments.map(async docu => {
                    // console.log("------> ITERATING DOCS FOR EDITING <---------")
                    // console.log(docu)
                    //let docId = (docu._id).toString()
                    //console.log(docId)
                    if(docu.type.value == `${messageResources.DOCUMENTS.BC}`){
                        //console.log("------> IS BIRTH <---------")
                        addNew = false;
                        //console.log(formData.cert_path)
                        //axios.put(`/docs/${docId}`,{link: formData.cert_path})
                        !(docu.url_link === formData.cert_path) && await updateGooDocumentFromStudent({id: docu._id, url_link: formData.cert_path, stuId: formData._id, stuName: `${studentData?.name} ${studentData?.surname}`}).unwrap()
                        
                    }
                })

                //console.log("------> ADD NEW AFTER TRYING TO EDIT DOC <---------")
                //console.log(addNew)

                if(addNew){
                    //console.log("------> ADDING BIRTH <---------")
                    const gooDoc = {}
                    gooDoc.type = {}
                    gooDoc.type.value = `${messageResources.DOCUMENTS.BC}`
                    gooDoc.type.label = `${messageResources.DOCUMENTS.BC}`
                    gooDoc.url_link = formData.cert_path
                    gooDoc.stuId = studentData._id
                    gooDoc.description = isEdit ? `${messageResources.DOCUMENTS.AUTO_DESC}` : `${messageResources.DOCUMENTS.AUTO_DESC_REG}`
    
                    const newDocRes = await addGooDocumentFromStudent(gooDoc).unwrap()

                    formData.gooDocuments.push(newDocRes._id)
                    
                }
                
            }

            //console.log(formData)

            if(formData.pass_path){

                //console.log("<><><><><><> ADDING OR EDITING DOC <><><><><><>")

                let addNew = true;
                isEdit && studentData.gooDocuments.map(async docu => {

                    //console.log("-----------> ITERATING DOCS <------------")
                    //console.log(docu)
                    let docId = (docu._id).toString()
                    //console.log(docId)
                    if(docu.type.value == `${messageResources.DOCUMENTS.PASSPORT}`){
                        //console.log(formData.pass_path)
                        //axios.put(`/docs/${docId}`,{link: formData.pass_path})
                        addNew = false;
                                                                        
                        !(docu.url_link === formData.pass_path) && await updateGooDocumentFromStudent({id: docu._id, url_link: formData.pass_path, stuId: formData._id, stuName: `${formData?.name} ${formData?.surname}`}).unwrap()
                        
                    }
                })


                if(addNew){

                    const gooDoc = {}
                    gooDoc.type = {}
                    gooDoc.type.value = `${messageResources.DOCUMENTS.PASSPORT}`
                    gooDoc.type.label = `${messageResources.DOCUMENTS.PASSPORT}`
                    gooDoc.url_link = formData.pass_path
                    gooDoc.stuId = studentData._id
                    gooDoc.description = isEdit ? `${messageResources.DOCUMENTS.AUTO_DESC}` : `${messageResources.DOCUMENTS.AUTO_DESC_REG}`
    
                    const newDocRes = await addGooDocumentFromStudent(gooDoc).unwrap()

                    formData.gooDocuments.push(newDocRes._id)
                    
                }
                
            }


            if(formData.lrs){

                let addNew = true;
                isEdit && studentData.gooDocuments.map(async docu => {

                    let docId = (docu._id).toString()
                    //console.log(docId)
                    if(docu.type.value == `${messageResources.DOCUMENTS.LRS}`){
                        //console.log(formData.pass_path)
                        //axios.put(`/docs/${docId}`,{link: formData.lrs})
                        addNew = false;
                        !(docu.url_link === formData.lrs) && await updateGooDocumentFromStudent({id: docu._id, url_link: formData.lrs}).unwrap()
                        
                    }
                })


                if(addNew){

                    const gooDoc = {}
                    gooDoc.type = {}
                    gooDoc.type.value = `${messageResources.DOCUMENTS.LRS}`
                    gooDoc.type.label = `${messageResources.DOCUMENTS.LRS}`
                    gooDoc.url_link = formData.lrs
                    gooDoc.stuId = studentData._id
                    gooDoc.description = isEdit ? `${messageResources.DOCUMENTS.AUTO_DESC}` : `${messageResources.DOCUMENTS.AUTO_DESC_REG}`
    
                    const newDocRes = await addGooDocumentFromStudent(gooDoc).unwrap()

                    formData.gooDocuments.push(newDocRes._id)
                    
                }
                
            }

            // console.log("------> FORMDATA AFTER ALL <---------")
            // console.log(formData);

            if(isEdit){
                
                  // SEE ABOVE WHY AT 15:00
                if(formData.passExpi) formData.passport_expiry = Moment(formData.passExpi).format('DD MMMM YYYY 15:00:00') 
                if(formData.dob) formData.date_birth = Moment(formData.dob).format('DD MMMM YYYY 15:00:00') 

                // DON"T FORGET UNWRAP TO CATCH POSSIBLE ERRORS
                const stuUpdated = await updateStudent(formData).unwrap()

                if(stuUpdated.email){
                    //REDIRECT TO LIST AFTER THIS
                    toast.success(messageResources.TOASTY.STUDENT_EDITED)
                    
                    navigate(`/students/${formData._id}`)
                }else {
                    toast.error(messageResources.TOASTY.STUDENT_NOT_ADDED)
                }

            }else{

                //const nokAdded = await axios.post("/nofKin/add",formData)

                // FIRST LETTER CAPITAL
                // myString.trim().replace(/^\w/, (c) => c.toUpperCase());

                // THE ABOVE AXIOS CALL WILL BE REPLACED BY SLICE CALLS AS BELOW
                const newStu = await addNewStudent(formData).unwrap()

                //console.log(res.data);
                //console.log(res.data.email);
                if(newStu.email){

                    //REDIRECT TO LIST AFTER THIS
                    toast.success(messageResources.TOASTY.STUDENT_ADDED)
                    
                    navigate('/students')
                }else {
                    toast.error(messageResources.TOASTY.STUDENT_NOT_ADDED)
                }

            }
            
        }catch(err){
            console.log(err)
        }
    }

	


    return (
        
        <>
            <Breadcrumb buttonsInfo={buttonsInfo} pageTitle={isEdit ? `${messageResources.OPS_TITLES.EDIT} ${studentData?.name} ${studentData?.surname}'s ${messageResources.OPS_TITLES.DETAILS}` : messageResources.OPS_TITLES.STU_ADD}/>
        
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <Tabs   defaultActiveKey="pending" 
                                id="uncontrolled-tab-example" 
                                className="mb-3">

                            <Tab    eventKey="pending" 
                                    tabClassName={errors?.name || errors?.surname 
                                                    || errors?.cert_path || errors?.passExpi || errors?.passport_number 
                                                    || errors?.nin || errors?.email || errors?.ethnicity 
                                                    || errors?.gender || errors?.dob || errors?.address
                                                    || errors?.borough|| errors?.contact_number ? "redLetters" : ""} 
                                    title={messageResources.STUDENT.PERSONAL_DATA} >
            
                        
                                    <div className="card card-box">
                                        {/* <div className="card-head">
                                            <header>{messageResources.STUDENT.PERSONAL_DATA}</header>
                                            <div className="tools">
                                                <a className="t-collapse btn-color fa fa-chevron-down"
                                                    href="javascript:;"></a>
                                            </div>
                                        </div> */}
                                        <div className="card-body " id="bar-parent">
                                            
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label>{messageResources.COMMON.NAME} 
                                                                {errors?.name && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                                            </label>
                                                            <input 	type="text" 
                                                                    className="form-control" 
                                                                    {...register('name')}
                                                                    placeholder="Student's Name"/>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label >{messageResources.COMMON.SURNAME}
                                                                {errors?.surname && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                                            </label>
                                                            <input 	type="text" 
                                                                    className="form-control" 
                                                                    {...register('surname')}
                                                                    placeholder="Student's Surname"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label>{messageResources.COMMON.GENDER}
                                                                {errors?.gender && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                                            </label>

                                                            <Controller
                                                                name="gender"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select options={genderOptions} 
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />	
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label>{messageResources.COMMON.PRONOUN}</label>

                                                            <Controller
                                                                name="pronoun"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select options={pronuonOptions} 
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />	
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label>{messageResources.STUDENT.ETHNICITY}
                                                                {errors?.ethnicity && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                                            </label>

                                                                <Controller
                                                                    name="ethnicity"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                    <Select options={ethnicitiesSorted} 
                                                                        {...field}
                                                                        />
                                                                    )}
                                                                />	
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label>{messageResources.COMMON.DOB}
                                                                {errors?.dob && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                                            </label>
                                                
                                                            <Controller
                                                                control={control}
                                                                name='dob'
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        //value={Moment(studentData.date_birth).format('DD MMMM yyyy')}
                                                                        onChange={(date) => field.onChange(date)}
                                                                        selected={field.value ? field.value : Date.parse(studentData?.date_birth) }
                                                                        //locale="en-GB"
                                                                        dateFormat="d MMMM yyyy"
                                                                        placeholderText={messageResources.COMMON.SELECT_DATE}   
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-1">
                                                        <div className="form-group">
                                                            <label >{messageResources.STUDENT.NINS}
                                                                {/* {errors?.nin && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>} */}
                                                                {errors?.nin && <span className="errorSpan"> {errors.nin.message} </span>}
                                                            </label>
                                                            <input 	type="text" 
                                                                    className="form-control" 
                                                                    {...register('nin')}
                                                                    placeholder={messageResources.STUDENT.NIN}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label>{messageResources.STUDENT.BOROUGH}
                                                                {errors?.borough && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                                            </label>
                                                                <Controller
                                                                    name="borough"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <Select options={boroughsSorted} 
                                                                            {...field}
                                                                        />
                                                                    )}
                                                                />	
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label for="simpleFormEmail">{messageResources.COMMON.EMAIL}
                                                                {errors.email && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED_EMAIL} </span>}
                                                            </label>
                                                            <input 
                                                                type="email" 
                                                                className="form-control" 
                                                                //disabled={contactData?.email && contactData?.isVerified && "true"}
                                                                //value={contactData?.email ? contactData?.email : contactData?.emailFixed ? contactData?.emailFixed : ""}
                                                                {...register('email')}
                                                                placeholder={messageResources.COMMON.EMAIL}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label for="simpleFormEmail">{messageResources.CONTACT_INFO.NUMBER}
                                                                {errors?.contact_number && <span className="errorSpan">  {errors.contact_number.message} </span>}
                                                            </label>
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                {...register('contact_number')}
                                                                placeholder={messageResources.CONTACT_INFO.NUMBER}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-1">
                                                        <div className="form-group">
                                                            <label>{messageResources.STUDENT.PHOTO}</label>
                                                            
                                                        
                                                                <Controller
                                                                    control={control}
                                                                    name='isPhoto'
                                                                    render={({ field }) => (
                                                                        <Toggle
                                                                            defaultChecked={studentData?.isPhoto}
                                                                            aria-label='No label tag'
                                                                            onChange={(value) => field.onChange(value)}
                                                                            value={studentData?.isPhoto ? studentData?.isPhoto : field.value}
                                                                            //checked={studentData.photo ? true : false}
                                                                        />
                                                                    )}
                                                                />	
                                                        
                                                                
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label >{messageResources.COMMON.PICTURE}</label>
                                                            <input 	type="file" 
                                                                    className="form-control" 
                                                                    {...register('picture')}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-1">
                                                        <div className="form-group">
                                                            {studentData?.picture_path
                                                                ? <img alt="" style={{width:"50px"}} src={studentData.picture_path} /> 
                                                                : <img alt="" style={{width:"65px"}} src={noProfile} />}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    
                                                <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{messageResources.STUDENT.LANG}
                                                                {/* {errors?.condition && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>} */}
                                                            </label>
                                                            <Controller
                                                                name="languages"
                                                                control={control}
                                                                render={({ field }) => (
                                                                <Select options={languagesSpokenOptions.sort()} 
                                                                    isMulti
                                                                    defaultValue={studentData?.languages}
                                                                    isClearable={true}
                                                                    {...field}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>{messageResources.STUDENT.LRS}
                                                                {errors?.lrs && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                                            </label>
                                                            <input type="text" 
                                                                    className="form-control" 
                                                                    {...register('lrs')}
                                                                    placeholder={messageResources.STUDENT.LRS}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {errors?.passExpi || errors?.cert_path || errors?.pass_path 
                                                        ? errors.passExpi?.message 
                                                            ? <span className="errorSpan">  {errors.passExpi?.message} </span>
                                                            : errors.cert_path?.message
                                                                ? <span className="errorSpan">  {errors.cert_path?.message} </span>
                                                                : errors.pass_path?.message
                                                                    ? <span className="errorSpan">  {errors.pass_path?.message} </span>
                                                                    : ""
                                                        : ""
                                                    }

                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label>{messageResources.STUDENT.PASSPORT_NUM}</label>
                                                            <input type="text" 
                                                                    className="form-control" 
                                                                    {...register('passport_number')}
                                                                    placeholder={messageResources.STUDENT.PASSPORT_NUM}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>{messageResources.STUDENT.PASS_LINK}
                                                                {/* {errors?.cert_path && <span className="errorSpan">  {errors?.cert_path.message} </span>} */}
                                                            </label>
                                                            <input 	type="text" 
                                                                    className="form-control" 
                                                                    {...register('pass_path')}
                                                                    placeholder={messageResources.STUDENT.LINK_PLACEHOLDER}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label>{messageResources.STUDENT.PASSPORT_EXPIRY}
                                                                {/* {errors?.passExpi && <span className="errorSpan">  {errors.passExpi.message} </span>} */}
                                                            </label>
                                                            <Controller
                                                                control={control}
                                                                name='passExpi'
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        onChange={(date) => field.onChange(date)}
                                                                        selected={field.value ? field.value : Date.parse(studentData?.passport_expiry)}
                                                                        dateFormat="d MMMM yyyy"
                                                                        minDate={new Date()}
                                                                        placeholderText={messageResources.COMMON.SELECT_DATE}
                                                                        peekNextMonth
                                                                        isClearable
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                    />
                                                                )}
                                                            />
                                                        </div>        
                                                    </div>

                                                    <div className="col-md-1">
                                                        <div className="form-group">
                                                            <label>{messageResources.STUDENT.PASSPORT_UK}</label>
                                                
                                                                <Controller
                                                                    control={control}
                                                                    name='isUkPassport'
                                                                    render={({ field }) => (
                                                                        <Toggle
                                                                            defaultChecked={studentData?.isUkPassport}
                                                                            aria-label='No label tag'
                                                                            onChange={(value) => field.onChange(value)}
                                                                            value={studentData?.isUkPassport ? studentData?.isUkPassport : field.value}
                                                                            //checked={studentData.photo ? true : false}
                                                                        />
                                                                    )}
                                                                />	
                                                        
                                                                
                                                        </div>
                                                    </div>
                                                
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{messageResources.STUDENT.DOB_LINK}
                                                                {/* {errors?.cert_path && <span className="errorSpan">  {errors?.cert_path.message} </span>} */}
                                                            </label>
                                                            <input 	type="text" 
                                                                    className="form-control" 
                                                                    {...register('cert_path')}
                                                                    placeholder={messageResources.STUDENT.LINK_PLACEHOLDER}/>
                                                        </div>
                                                    </div>

                                            
                                                
                                                </div>
                                        </div>
                                    </div>

                                    <ContactInfo contactData={studentData} pathField="address"/>
                        
                        </Tab>

                        {!isEdit && <Tab eventKey="nexOKin" title={messageResources.OPS_TITLES.NOK}
                            tabClassName={errors?.nokName || errors?.nokSurname 
                                || errors?.nokContact_number || errors?.kinship  ? "redLetters" : ""}>
                            {/* <NextofKin personData={studentData}/> */}
                            <NokForm />
                        </Tab> }                                              

                        {/* <Tab eventKey="ehcpData" title={messageResources.STUDENT.EHCP}>

                        <div className="card-body " id="bar-parent">
                                
                        
                        </div>
                        
                            </Tab> */}
                            
                        </Tabs>
                        <div className="col-md-2 col-sm-2">
                            <button type="submit" style={{marginTop:"20px"}} className="btn btn-primary" ref={btnRef} onClick={methods.handleSubmit(onSubmit)}>{messageResources.COMMON.SUBMIT}</button>
                        </div>
                    </div>
            </form>
        </FormProvider>	

                
                {/* 
                    
                </div>
                <div className="card card-box">
                    <div className="card-head">
                        <header>{messageResources.COMMON.COMMENTS}</header>
                        <div className="tools">
                            <a className="t-collapse btn-color fa fa-chevron-down"
                                href="javascript:;"></a>
                        </div>
                    </div>
                    <div className="card-body " id="bar-parent">
                    
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea 	className="form-control" 
                                                    rows="3"
                                                    {...register('comments')}
                                                    placeholder="Enter ..."></textarea>
                                    </div>
                                </div>
                            </div>
                    </div>		
                </div> 
                
                <button type="submit" className="btn btn-primary" onClick={methods.handleSubmit(onSubmit)}>{messageResources.COMMON.SUBMIT}</button>
            </form>
            </FormProvider>	

            </div>
            </div> */}
                        
        </>        
    )
}

export default memo(StudentForm);
