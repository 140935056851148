import { apiSlice } from "../api/apiSlice";
import { logout, setCredentials } from "./authSlice";


export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: '/auth/login',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        sendLogout: builder.mutation({
            query: toInsert => ({
                url: '/auth/logout',
                method: 'POST',
                body: { ...toInsert }
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled}) {
                try {
                    // in this case data would be "cookie cleared" as we
                    // set in the api controller
                    //console.log("|---> DESDE EL COMPONENTE <-----|")
                    //const { data } = 
                    await queryFulfilled
                    //console.log(data)
                    // set the token to null in local state with logout
                    dispatch(logout())

                    // setTIme put to give it time to unsubscribe queries, RTK queries are 
                    // supposed to unsubscribe when to component unmounts but we give it
                    // this tiem out to make sure.
                    setTimeout(() => {
                        dispatch(apiSlice.util.resetApiState()) // this clears the cache and queries subscriptions
                    }, 1000) // one sec
                   
                }catch(err){
                    console.log(err)
                }
            }
        }),
        refresh: builder.mutation({
            query: () => ({
                url: '/auth/refresh',
                method: 'GET'
            }),
            // every time we use refresh it will also setcredentials with the token
            async onQueryStarted(arg, {dispatch, queryFulfilled}){
                try { 
                    //console.log("|---> refresh mutation  refresh <-----|")
                    const { data } = await queryFulfilled
                    //console.log(data)
                    const { accessToken } = data
                    dispatch(setCredentials({accessToken}))
                }catch(err){
                    console.log(err)
                }
            }
        }),
        registerStaff: builder.mutation({
            query: staffToRegister => ({
                url: '/auth/register',
                method: 'POST',
                body: staffToRegister
            })    
        }),
        forgot: builder.mutation({
            query: forgotForm => ({
                url: '/auth/forgot',
                method: 'POST',
                body: forgotForm
            })    
        })
    })
})


export const {
    useLoginMutation,
    useSendLogoutMutation,
    useRefreshMutation,
    useRegisterStaffMutation,
    useForgotMutation
} = authApiSlice