import React, {useState,useEffect} from "react";
import {Modal, Button} from 'react-bootstrap';
import { messageResources } from "../../assets/util/messageResources";
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker'
import { TextField } from "@mui/material";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router'
import { useForm, Controller, FormProvider } from "react-hook-form"
import Select from 'react-select'
import Toggle from 'react-toggle'
import Moment from "moment";
import addLocation from "../../assets/util/addLocation"
import { achievementOptions } from '../../assets/util/selectOptions'
import { selectCurrentToken } from '../../redux/auth/authSlice'
import { useUpdateProgrammeMutation } from '../../redux/programme/programmeApiSlice'
import { selectStudentById } from "../../redux/student/studentSlice";
import { isFirstHalfOfYear } from "../../assets/util/academiYear";


export default function EditSubjectModal({progId, stuId, subjectToEdit}) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const studentFormState = useSelector((state) => selectStudentById(state, stuId))

    const [updateSubject] = useUpdateProgrammeMutation()

    const navigate = useNavigate()

    useEffect(() => {
    
        if(subjectToEdit.actualDate) subjectToEdit.actualDate = Date.parse(subjectToEdit.actualDate)
        if(subjectToEdit.endDate) subjectToEdit.endDate = Date.parse(subjectToEdit.endDate)
        if(subjectToEdit.startDate) subjectToEdit.startDate = Date.parse(subjectToEdit.startDate)
        
        reset(subjectToEdit)

	}, [subjectToEdit]);

    const methods = useForm({});


	const {
        register,
        handleSubmit,
        reset,
		control,
        getValues
    } = methods;

    const token = useSelector(selectCurrentToken)

    //console.log(errors)

    const onSubmit = async (formData) => {

        //console.log("NO ERRORS")
        //console.log(formData);
        //formData.ehcpDate ? ehData.date = Moment(formData.ehcpDate).format('DD MMMM YYYY 15:00:00') : ehData.date = studentInfo.ehcp?.date
    
        // CAN THIS BE REPLACED BY locale="en-GB" IN THE DATEPICKER???
        if(formData.actualDate) formData.actualDate = Moment(formData.actualDate).format('DD MMMM YYYY 15:00:00')
        if(formData.endDate) formData.endDate = Moment(formData.endDate).format('DD MMMM YYYY 15:00:00')
        if(formData.startDate) formData.startDate = Moment(formData.startDate).format('DD MMMM YYYY 15:00:00')

        let values = getValues()
        //console.log(values);

        values.progId = progId

        if(values.hours) values.hours = parseFloat(values.hours)

        // NOP ACHIEVEMENT THEN NO COMPLETED
        if(values.achievement?.value == null){
            values.completed = null
        }else if(values.achievement?.value && !values.completed) {
            values.completed = false
        }
        

		try{
			
			//console.log("<><><><><><> DOCUMENT onSubmit DOCUMENT  <><><><><><>") 
            //formData.stuId = stuId
            values.stuId = stuId
            values.stuName = studentFormState?.name+" "+studentFormState?.surname
            //await addLocation(values) 
                
            const updatedProgramme = await updateSubject(values).unwrap()
            
            setShow(false)
            
            let thisYearForProgram
            // start aca year changes depending on where we are in the year
            if(isFirstHalfOfYear()){
                thisYearForProgram = new Date().getFullYear()-1
            }else{
                thisYearForProgram = new Date().getFullYear()
            }
            
            const currentStartDateAcaYear = new Date(thisYearForProgram, 7, 31) 

            // EDITING CURRENT OR PREVIOUS PROGRAM
            if(updatedProgramme.isActive 
                || (!updatedProgramme.isActive && new Date(updatedProgramme.startDate) < currentStartDateAcaYear)){
                toast.success(messageResources.TOASTY.SUBJECT_EDITED)
                navigate(`/academic/mark/${stuId}`, { state: { programme: updatedProgramme, showButton: true } });
            }else{
                toast.success(messageResources.TOASTY.PROGRAM_MARKED,{
                    autoClose: 12002,
                })
                navigate('/academic/completed')
            }
            

		}catch(err){
			console.log(err);
		}
	}


	

    
    return (
        <>
    
        <button style={{marginRight: '10px'}} className="btn btn-outline-success btn-circle" onClick={handleShow}>
            {messageResources.BUTTON.MARK}
        </button>
        
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
            <Modal.Title>
                <div className="row">
                <div className="col-md-1"><i style={{fontSize: '32px'}} className="material-icons">comment</i></div>
                    <div className="col-md-10"> {subjectToEdit?.label}</div>
                </div>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body><div className="row" id="forCalendar">
                                
                                <FormProvider {...methods}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                    
                                            <div className="card card-box">
                                                <div className="card-body " id="bar-parent">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{messageResources.PROGRAMME.START} </label>
                                                            <Controller
                                                                control={control}
                                                                name='startDate'
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        onChange={(date) => field.onChange(date)}
                                                                        dateFormat="d MMMM yyyy"
                                                                        selected={field.value ? field.value : subjectToEdit?.startDate }
                                                                        placeholderText="Select Date"
                                                                        peekNextMonth
                                                                        portalId="forCalendar"
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{messageResources.PROGRAMME.END} </label>
                                                            <Controller
                                                                control={control}
                                                                name='endDate'
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        onChange={(date) => field.onChange(date)}
                                                                        dateFormat="d MMMM yyyy"
                                                                        selected={field.value ? field.value : subjectToEdit?.endDate }
                                                                        placeholderText="Select Date"
                                                                        peekNextMonth
                                                                        portalId="forCalendar"
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{messageResources.SUBJECT.ACTUAL} </label>
                                                            <Controller
                                                                control={control}
                                                                name='actualDate'
                                                                id="actualDate"
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        onChange={(date) => field.onChange(date)}
                                                                        dateFormat="d MMMM yyyy"
                                                                        //selected={field.value ? field.value : subjectToEdit?.actualDate }
                                                                        selected={field.value}
                                                                        placeholderText="Select Date"
                                                                        peekNextMonth
                                                                        portalId="forCalendar"
                                                                        showMonthDropdown
                                                                        isClearable
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{messageResources.SUBJECT.HOURS} </label>
                                                        <Controller
                                                            control={control}
                                                            name="hours"
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    //value={value}
                                                                    fullWidth
                                                                    placeholder={messageResources.PROGRAMME.WEEKLY}
                                                                    //defaultValue={14}
                                                                    type="number"
                                                                    inputProps={{ min: "0", step: "0.5"}}
                                                                    InputProps={{ disableUnderline: true, className: "form-control"}}
                                                                    
                                                                />
                                                            )}
                                                            //defaultValue={defaultValue}
                                                        />
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{messageResources.SUBJECT.COMPLETED}</label>
                                                                <Controller
                                                                    control={control}
                                                                    name='completed'
                                                                    render={({ field }) => (
                                                                        <Toggle
                                                                            defaultChecked={subjectToEdit?.completed}
                                                                            aria-label='No label tag'
                                                                            onChange={(value) => field.onChange(value)}
                                                                            value={subjectToEdit?.completed ? subjectToEdit?.completed : field.value}
                                                                            //checked={studentData.photo ? true : false}
                                                                        />
                                                                    )}
                                                                />	
                                                        
                                                                
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="form-group">
                                                            <label>{messageResources.SUBJECT.ACHIEVEMENT} </label>
                                                            <Controller
                                                                name="achievement"
                                                                control={control}
                                                                render={({ field }) => (
                                                                <Select options={achievementOptions} 
                                                                    isClearable={true}
                                                                    {...field}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="fake-modal-footer">
                                            <Button variant="secondary" onClick={handleClose}>
                                                {messageResources.BUTTON.CANCEL}
                                            </Button>
                                            <button className="btn btn-modal" onClick={() => methods.handleSubmit(onSubmit)}>
                                                
                                                    {messageResources.BUTTON.MARK}
                                                   
                                            </button>
                                        </div>
                                    </form>
                                </FormProvider>

                            </div></Modal.Body>
            {/* <Modal.Footer>
            
            </Modal.Footer> */}
        </Modal>
        </>
    );
}