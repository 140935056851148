import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../redux/auth/authSlice'
import {jwtDecode} from 'jwt-decode'

// ROLES IN SEROOTS
// { value: 1, label: 'USER' },
// { value: 2, label: 'ADMINISTRATOR' },
// { value: 3, label: 'STUDENT REGISTER' },
// { value: 4, label: 'STUDENT EHCP' },
// { value: 5, label: 'STUDENT EDIT NO NOTIS' },

const useAuth = () => {

    //console.log("|-------------> useAuth HOOK useAuth <><><><><><><><><><>")

    const token = useSelector(selectCurrentToken)
    //const token = useSelector((state) => state.auth.token);
    let isStuRegister = false;
    let isStuEhcp = false;
    let isStuEditNoNotis = false;
    let isAdmin = false;
    let status = "Employee"

    //console.log("|-------------> after selectCurrentToken <----------|>")
    //console.log(token)

    if(token) {
        const decoded = jwtDecode(token)
        // this has to be the same as we signed the token in auth controller in api
        const { email, roles, id } = decoded.UserInfo

        isAdmin = roles.includes(2)
        isStuRegister = roles.includes(3)
        isStuEhcp = roles.includes(4)
        isStuEditNoNotis = roles.includes(5)

        return { email, roles, id, isAdmin, isStuRegister, isStuEhcp, isStuEditNoNotis, status }

    }

    return { email: '', roles: [], id: '', isAdmin, isStuRegister, isStuEhcp, isStuEditNoNotis, status }


}

export default useAuth