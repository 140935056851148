import React, { Component } from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Messages from '../../components/messages/Messages'
import { messageResources } from '../../assets/util/messageResources'

export class ErrorBoundary extends Component {

    // ENTRAMOS AQUI WHEN THE ARROR IS NOT CHATCHED BY ANY COMPONENT
    constructor(props) {
        super(props)

        // INITIAL STATE
        this.state = {
            error: null,
        }
    }

     // FIRST IT CHECKS IF THERE IS AN ERROR
    // IF THERE IS ONE IT WILL CHANGE THE STATE
    static getDerivedStateFromError(error){
        console.log(" -------------< ErrorBoundary getDerivedStateFromError ErrorBoundary <-------------<")
        //console.log(error)
        return {error};
    }

    componentDidCatch(error,errorInfo){
        console.log(" <><><><><><>><><><>< ErrorBoundary error ErrorBoundary <><><><><><><><><><>")
        console.log(error)
        console.log(" <><><><><><>><><><>< ErrorBoundary errorInfo ErrorBoundary <><><><><><><><><><>")
        //console.log(errorInfo)
    }



    render() {
        // IF THERE IS AN ERROR RETURN ERROR PAGE
        if(this.state.error)
        return (
           
            <div className="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md page-full-width header-white white-sidebar-color logo-indigo">
                <div className="page-wrapper">
                    <Navbar />
                    <div className="page-container">
                        <div className="page-content-wrapper">
                            <div className="page-content">
                                
                                <Breadcrumb pageTitle={messageResources.OPS_TITLES.ERROR}/>
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* <Messages message={messageResources.BANNER.ERROR_MESSAGE} type="error" /> */}


                                        {/* <Messages message={this.state.error.Error} type="error" /> */}

                                        <h1>There was an error during the transaction. Please try again or contact your administrator</h1>
                                        {/* <h5>{JSON.stringify(this.state.error)}</h5> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <Footer />
                </div>
            </div> 
        )

        // THERE IS NO ERROR SO RETURN CHILDREN COMPONENTS
        // WHICH ARE THE COMPONENTS WRAPPED BY THIS COMPONENT 
        return this.props.children
    }
}

export default ErrorBoundary