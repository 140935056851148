import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import axios from "axios"
import InputSelect from '../../components/forms/Input/InputSelect'
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import PacmanLoader from 'react-spinners/PacmanLoader'
import List from '../../components/list/List'
import { useEffect, useState, useRef } from 'react'
import { messageResources } from '../../assets/util/messageResources'
import { useForm, Controller, FormProvider } from "react-hook-form"
import Moment from 'moment'
import { trackerOptions } from '../../assets/util/selectOptions'
import { useGetStaffsQuery } from '../../redux/staff/staffApiSlice'



const UserTracker = () => {

    const columnsForRecords = [

        { title: "Action Type",  field: "actionType" }, 
        { title: "Staff", field: "userTrigger" }, 
        { title: "Date", field: "createDate", defaultSort: "desc", type: 'datetime', dateSetting: { format: 'dd MMMM yyyy HH:mm:ss'} },
        { title: "Student", field: "student", render:rowData=><Link to={`/students/${rowData.studentId}`}> {rowData.student} </Link> }, 
        { title: "Description",  width: "40%", field: "description" },
        { title: "Location",  field: "location" }, 
        
    ];

   //console.log("|-----> StudentsList COMPONENT StudentsList <------|")

    let ehBtnRef = useRef();
    const [records,setRecords] = useState([])


    useEffect(() => {       
        window.scrollTo(0, 0);
    },[])


    const methods = useForm({});
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = methods;

    //console.log(errors)


    // useEffect(async () => {
    //     window.scrollTo(0, 0);
    //     //console.log("-------> useEffect UserTracker ")
    //     //axios.get(`https://www.seroots.org.uk/template/
    //     //axios.get(`http://localhost:8800/serruts/template/openIlp/
    //     const response = axios.get(`http://localhost:8800/serruts/mysql/allTrack`)
    //     //console.log(response)
    //     response.then(todos => {
    //         //console.log("-------> todos todos ")
    //         //console.log(todos.data)
    //         setRecords(todos.data)
    //     })
    
    // }, []);

   
    const {
        data: staffs,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetStaffsQuery()


    let content

    if(isLoading){
        content =   <div style={{position: 'fixed', top: '50%', left: '50%'}}>
                        <PacmanLoader  color="#84bdb1" size={58}/>
                    </div>
    }else if(isSuccess){
        const {ids, entities} = staffs


        const listContent = ids?.length
            ? ids.map(staffId => entities[staffId] )
            : null

        // CHECKING IF LIST IS CORRECT
        //console.log(listContent)
        var activeList = [] ;
        listContent.map(staffMem => {
            if(staffMem.isActive){
                var uno = {}
                uno.value = staffMem.name+" "+staffMem.surname
                uno.label = staffMem.name+" "+staffMem.surname
                activeList.push(uno)
            }
        })

    }else if(isError){
        content = <p>{error}</p>
    }




    const onSubmit = async (formData) => {

        if(ehBtnRef.current){
            //console.log("------> DISABLE BUTTON <---------")
            ehBtnRef.current.setAttribute("disabled", "disabled");
        }

		try{

            formData.logDate ? formData.logDate = Moment(formData.logDate).format('YYYY-MM-DD') : formData.logDate = null

            //const result = await axios.post("http://localhost:8800/serruts/mysql/logsBy",formData)
            const result = await axios.post("https://www.seroots.org.uk/mysql/logsBy",formData)
            setRecords(result)

            if(ehBtnRef.current){
                //console.log("------> DISABLE BUTTON <---------")
                ehBtnRef.current.removeAttribute("disabled");
            }


        }catch(err){
			console.log(err);
		}
	}

    return ( 

        <>    
            <Breadcrumb pageTitle={`${messageResources.OPS_TITLES.USER_TRACK} `}/>

            <div className="card card-topline-aqua">
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        
                            <div className="card-body " id="bar-parent">
                                <div className="row">
                                    
                                    <InputSelect 
                                        slots="col-md-2" 
                                        label={messageResources.TRACKER.TYPE} 
                                        errors={errors}
                                        control={control}
                                        inputName="type"
                                        isClearable={true}
                                        original={false}
                                        selectOptions={trackerOptions}
                                    />

                                    <InputSelect 
                                        slots="col-md-2" 
                                        label={messageResources.TRACKER.STAFF} 
                                        control={control}
                                        errors={errors}
                                        inputName="staff"
                                        isClearable={true}
                                        original={false}
                                        selectOptions={activeList}
                                    />
                                
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>{messageResources.STUDENT.EHCP_DATE}
                                                {errors.ehcpDate && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                            </label>
                                            <Controller
                                                control={control}
                                                name='logDate'
                                                render={({ field }) => (
                                                    <DatePicker
                                                        onChange={(date) => field.onChange(date) }
                                                        selected={field.value}
                                                        dateFormat="d MMMM yyyy"
                                                        placeholderText="Select Date"
                                                        peekNextMonth
                                                        portalId="root-portal"
                                                        isClearable
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-1">
                                        <button type="submit" style={{marginTop:"25px"}} className="btn btn-primary" ref={ehBtnRef} onClick={methods.handleSubmit(onSubmit)}>{messageResources.BUTTON.SEARCH}</button>
                                    </div>
                                </div>
                            </div>
                        
                    </form>
                </FormProvider>
            </div>    

            {records.data?.length > 0 && 
                <div className="row">
                    <div className="col-md-12">
                        <List   titleForTable="" 
                                dataForTable={records.data} 
                                columnsForTable={columnsForRecords}
                                search={false}
                                colButton={true}
                                filter={true}
                                pageSize={records.data?.length < 100 ? records.data?.length : 100}/>
                    </div>
                </div>
            }
            
        </>

    
    )
}

export default UserTracker