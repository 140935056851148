import React from 'react'
import ClipboardCopy from '../../components/copyText/ClipboardCopy'
import noProfile from '../../assets/img/noProfile/noProfile.jpg'
import { Link } from 'react-router-dom'
import { messageResources } from '../../assets/util/messageResources'
import useAuth from '../../hooks/useAuth'
import ModalImage from "react-modal-image"
import Moment from 'moment'
import axios from 'axios'

const PdStudentCard = ({studentInfo}) => {

    const { roles } = useAuth()

    const deliverLand = async () => {
	
		//console.log(`|-------------------------> [1] <----`)

		const response = await axios.get(`https://www.seroots.org.uk/template/openLand/${studentInfo?._id}/${studentInfo?.activeProgramme?.programme?.label}`, {
			responseType: 'blob',
			headers: {
			'Content-Type': 'application/pdf'
			}
		})

        //Create a Blob from the PDF Stream
        const file = new Blob(
            [response.data], 
            {type: 'application/pdf'});
            
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            // console.log(`|-------------------------> [4] <----`)
            // console.log(fileURL)
            //Open the URL on new Window
            window.open(fileURL);
    }


    const deliverPdf = async () => {
	
		//console.log(`|-------------------------> [1] <----`)

		const response = await axios.get(`https://www.seroots.org.uk/template/openIlp/${studentInfo?._id}`, {
			responseType: 'blob',
			headers: {
			'Content-Type': 'application/pdf'
			}
		})
		
        // console.log("RESPONSE IN PdStudentCard COMPONENT")
        // console.log(response.data)
		
        // const response = await axios.get(`http://localhost:8800/serruts/template/openIlp/${studentInfo?._id}`, {
		// 	responseType: 'blob',
		// 	headers: {
		// 	'Content-Type': 'application/pdf'
		// 	}
		// })

		// console.log(`|-------------------------> [2] <----`)
		// console.log(response)

 
		//Create a Blob from the PDF Stream
		const file = new Blob(
			[response.data], 
			{type: 'application/pdf'});
		
		// console.log(`|-------------------------> [3] <----`)
		// console.log(file)
		
		//Build a URL from the file
		const fileURL = URL.createObjectURL(file);
		// console.log(`|-------------------------> [4] <----`)
		// console.log(fileURL)
		//Open the URL on new Window
		window.open(fileURL);
	}

    return (
        <div className="col-lg-2 col-md-12">
            <div className="card card-topline-aqua">
                <div className="card-body no-padding height-9">
                    <div className="row">
                            
                        <div className="profile-userpic">
                            <ModalImage
                                small={studentInfo?.picture_path 
                                    ? studentInfo?.picture_path 
                                    : noProfile}
                                large={studentInfo?.picture_path 
                                    ? studentInfo?.picture_path 
                                    : noProfile}
                                alt={`${studentInfo?.name} ${studentInfo?.surname}`}
                                hideDownload={true}
                                hideZoom={true}
                                className="modal-image"
                            />
                        
                            {/* <img src={studentInfo?.picture_path 
                                        ? studentInfo?.picture_path 
                                        : noProfile } className="img-responsive" alt=""/> */}
                        </div>
                    </div>
                    <div className="profile-usertitle">
                        {/* <div className="profile-usertitle-name">{studentInfo.name} {studentInfo.surname} </div> */}
                        <ClipboardCopy copyText={`${studentInfo?.name} ${studentInfo?.surname}`} cName="profile-usertitle-name" element="div"/>
                        { studentInfo?.pronoun?.label && <><br/>({studentInfo?.pronoun?.label}) </> } 
                    </div>
                    <ul className="list-group list-group-unbordered">
                        <li className="list-group-item">
                            <b>{messageResources.CONTACT_INFO.PHONE}</b> 
                            {/* <a className="pull-right">{studentInfo.borough?.value}</a> */}
                            <ClipboardCopy copyText={studentInfo?.contact_number} cName="pull-right" element="a" bClass="pull-right"/>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.COMMON.DOBS}</b> 
                            {/* <a className="pull-right">{Moment(studentInfo.date_birth).format('DD MMMM YYYY')}</a> */}
                            <ClipboardCopy copyText={Moment(studentInfo?.date_birth).format('DD MMMM YYYY')} bClass="pull-right" element="a"/>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.COMMON.AGE}</b> 
                            {/* <a className="pull-right">{studentInfo.age}</a> */}
                            <ClipboardCopy copyText={studentInfo?.age} cName="pull-right" element="a" bClass="pull-right"/>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.STUDENT.ETHNICITY}</b> 
                            {/* <a className="pull-right">{studentInfo.ethnicity?.value}</a> */}
                            <ClipboardCopy copyText={studentInfo?.ethnicity?.value} cName="pull-right" element="a" bClass="pull-right"/>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.STUDENT.BOROUGH}</b> 
                            {/* <a className="pull-right">{studentInfo.borough?.value}</a> */}
                            <ClipboardCopy copyText={studentInfo?.borough?.value} cName="pull-right" element="a" bClass="pull-right"/>
                        </li>
                        
                    </ul>
                    {studentInfo?.isActive &&  
                    
                        <div className="profile-userbuttons">
                            {!roles?.includes(6) && 
                                <Link to={`/students/edit/${studentInfo?._id}`} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
                                    {messageResources.OPS_TITLES.STU_EDIT}
                                </Link>
                            }
                            {studentInfo?.activeProgramme && 
                                <a className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" 
                                    style={{marginLeft: '8px'}}  onClick={deliverPdf}>{messageResources.BUTTON.ILP}</a> }

                            {!roles?.includes(6) && 
                                <a className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" 
                                    style={{marginLeft: '8px'}}  onClick={deliverLand}>{messageResources.BUTTON.LAND}</a> }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default PdStudentCard