import noProfile from '../../assets/img/noProfile/noProfile.jpg'
import List from '../../components/list/List'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import {messageResources} from '../../assets/util/messageResources'
import PacmanLoader from 'react-spinners/PacmanLoader'
import { Link } from 'react-router-dom'
import { useGetStaffsQuery } from '../../redux/staff/staffApiSlice'
import ModalImage from "react-modal-image"

const StaffList = () => {

    const columnsForStaffList = [
        { title: "Picture",  field: "picture_path", sorting:false, render:rowData=> <ModalImage
                                                                                        small={rowData.picture_path ? rowData.picture_path : noProfile}
                                                                                        large={rowData.picture_path ? rowData.picture_path : noProfile}
                                                                                        alt={`${rowData?.name} ${rowData?.surname}`}
                                                                                        hideDownload={true}
                                                                                        hideZoom={true}
                                                                                        className="pic-list"
                                                                                    />  }, 
        { title: "Name",  field: "name", defaultSort: "asc", render:rowData=><Link to={`/staff/${rowData._id}`}> {rowData.name} </Link> }, 
        { title: "Surname",  field: "surname"  },
        { title: "Email",  field: "email"  },
        { title: "Position",  field: "position.value"  },
        { title: "Roles",  width: "40%", field: "rolString"  },
        
        // { title: "User Registered",  field: "isUser", render: rowData => rowData.isUser === true ? "Yes" : "No" },
        
    ];

    //console.log("|------> StaffList COMPONENT <><><><><><>")
    //const [allStaff,setAllStaff] = useState([]);

    const {
        data: staffs,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetStaffsQuery()




    let content;
    
    if(isLoading){
        content =   <div style={{position: 'fixed', top: '50%', left: '50%'}}>
                        <PacmanLoader  color="#84bdb1" size={58}/>
                    </div>
    }else if(isSuccess){
        const {ids, entities} = staffs


        const listContent = ids?.length
            ? ids.map(staffId => entities[staffId] )
            : null

        // CHECKING IF LIST IS CORRECT
        //console.log(listContent)
        var activeList = [] ;
        var disabledList = [];
        listContent.map(staffMem => {
            if(staffMem.isActive){
                activeList.push(staffMem)
            }else{
                disabledList.push(staffMem)
            }
        })

        content =   
                    <>
                    <Breadcrumb pageTitle={messageResources.OPS_TITLES.STAFF_MEMBERS} />
                    <div className="row">
                        <div className="col-md-12">
                            <List titleForTable={`${messageResources.STAFF.ACTIVE} ${activeList.length}`} 
                                dataForTable={JSON.parse(JSON.stringify(activeList))} 
                                columnsForTable={columnsForStaffList} 
                                search={false}
                                filter={true}
                                pageSize={5}/>
                        </div>

                        <div className="col-md-12">
                            <List titleForTable={`${messageResources.STAFF.DISABLE} ${disabledList.length}`} 
                                dataForTable={JSON.parse(JSON.stringify(disabledList))} 
                                columnsForTable={columnsForStaffList} 
                                search={false}
                                filter={true}
                                pageSize={5}/>
                        </div>
                    </div>
                    </>

    }else if(isError){

        if(error.data.message == "refresh expired"){
            content =   <>
                            <Breadcrumb pageTitle="Your session has expired" />
                            
                            <div className="card card-topline-aqua">
                                <div className="card-body ">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p> 
                                                <a href="/login" >
                                                    Please click here to log in again
                                                </a> 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </>
        }else{
            <p> ERROR:  {error.data.message} </p>
        }



        // if(error.data.message == "refresh expired"){

        //     dispatch(apiSlice.util.resetApiState())
        //     //dispatch(logout())  
           
        //     toast.info(messageResources.TOASTY.SESSION_EXPIRED)
        //     content = <Navigate to="/login" state={{ from: location }} replace />
        // }else{

        //     content = <p> ERROR:  {error.data.message} </p>
        // }
    

    }


    return (
        content
    )
}

export default StaffList
