import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials } from '../auth/authSlice'
//import { noConflict } from 'handlebars/runtime'

// THIS WILL APPLY TO EVERY REQUEST SENT AND
// IT WILL INCLUDE THE TOKEN IN THE AUTHORIZATION HEADER
const baseQuery = fetchBaseQuery({
    baseUrl: 'https://www.seroots.org.uk/',
    //baseUrl: 'http://localhost:8800/serruts/',
    // this will send the httponly cookie
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        // getState allows us to get the current state of the application
        const token = getState().auth.token
        //console.log("|----> TOKEN IN getState() basequery <-----|")
        //console.log(token)
        // it sets the token in the header
        if(token){
            headers.set("authorization", `Bearer ${token}`)
        }
        //console.log(headers)
        //console.log("|----> token in header <-----|")
        return headers
    }
})


// QUERY WRAPPER TO SEND REFRASH TOKEN TO GET 
// NEW ACCESS TOKEN IF EXPIRED
const baseQueryWithReauth = async (args, api, extraOptions) => {
    //console.log(args) // request url, method, body
    //console.log(api) // signal, dispatch, getState()
    //console.log(extraOptions) // custom like {shout: true}

    let result = await baseQuery(args, api, extraOptions)

    // other status can be handled here
    if(result?.error?.status === 403){
        //console.log('|----> Sending Refesh TOKEN <-----|')

        // send refresh token to get new access token
        const refreshResult = await baseQuery('/auth/refresh', api, extraOptions)
        //console.log(refreshResult?.data)

        // this data coming from refresh should hold the 
        // access token generated by refresh endpoint
        if(refreshResult?.data) {
            //store the new token in our redux state
            api.dispatch(setCredentials({ ...refreshResult.data }))

            //retry original query with new access token
            result = await baseQuery(args, api, extraOptions)

        }else{

            if(refreshResult?.error?.status === 403) {
                //console.log('|----> ES 403 <-----|')
                refreshResult.error.data.messageBis = "Your login has expired. "
                
            }
            return refreshResult
        }
    }
    return result
}

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    // tags used for cached data
    tagTypes: ['Notifications', 'Student', 'Staff', 'GooDocuments'],
    endpoints: builder => ({})
})


