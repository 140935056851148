import React from 'react'
import MaterialTable from 'material-table';
import { ThemeProvider, createTheme } from '@mui/material';
import "./list.css"


const List = ({dataForTable,columnsForTable,filter,search,titleForTable,pageSize,colButton,group}) => {

    // console.log("<><><><><><> RENDERING LIST COMPONENT <><><><><><>")
	// console.log(dataForTable)
    //console.log(dataForTable?.length)

    const defaultMaterialTheme = createTheme();

    return (
    
            
        <div className="card card-topline-aqua">
            {/* <div className="card-head">
                <header>{tableTitle}</header>
                <div className="tools">
                    <a className="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                    <a className="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                    <a className="t-close btn-color fa fa-times" href="javascript:;"></a>
                </div>
            </div> */}
            <div className="card-body ">
                <div className="table-scrollable">
                    <ThemeProvider theme={defaultMaterialTheme}>
                        <MaterialTable 
                            
                            key={ pageSize }
                            columns={columnsForTable} 
                            data={dataForTable} 
                            title={titleForTable ? titleForTable : ""}
                            options={{
                                columnsButton: colButton,
                                exportButton: true,
                                grouping: group ? group : false,
                                exportFileName: "Data",
                                rowStyle:(data,index)=>
                                            !data.isProcessed && data.priority == 1 ? {background:"#f4ada5"}
                                            : !data.isProcessed && data.priority == 2 ? {background:"#f8eba0ec"}
                                            : !data.isProcessed && data.priority == 3 ? {background:"#a2d3fa"}
                                            : index%2==0 ? {background:"#f0ecec"}
                                            : null,
                                headerStyle: {
                                    //backgroundColor: '#01579b',
                                    // color: '#FFF',
                                    fontSize: '22px',
                                    fontStyle: 'italic',
                                    zIndex: 1
                                },
                                filtering: filter,
                                filterCellStyle: {
                                    border: '0'
                                },
                                search: search,
                                pageSizeOptions:[pageSize,10,15,20,30,40],
                                pageSize: pageSize,
                                paginationType: "stepped",
                                pagination: {
                                    labelDisplayedRows: '{count}'
                                },
                                showFirstLastPageButtons: false,
                                searchAutoFocus: true, 
                                //tableLayout: "auto"
                                //searchText: "System",
                                //showTitle: false,
                                
                            }}
                            
                            />
                    </ThemeProvider>
                </div>
            </div>
        </div>    
            
        
    )
}

export default List
