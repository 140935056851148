import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import List from '../../components/list/List'
import NewNotification from '../../components/notification/NewNotification'
import { useEffect } from 'react'
import Card from '../../components/card/Card'
import { Link } from 'react-router-dom'
import PieChart from '../charts/PieChart'
import { messageResources } from '../../assets/util/messageResources'
import { getGenderSeries, getLangSeries } from '../../assets/util/pieChartStats'
import { useSelector } from 'react-redux'
import useAuth from '../../hooks/useAuth'
import { toast } from 'react-toastify';
import { selectAllStudents } from '../../redux/student/studentSlice'
import { selectAllNotifications } from '../../redux/notifications/notificationsApiCalls'
import ClipboardCopy from '../../components/copyText/ClipboardCopy'


const HomeAdmin = () => {

    //console.log("|-------------> HomeAdmin COMPONENT HomeAdmin <---------------|")

    const columnsForAllStudents = [
        { title: "Name",  field: "name", defaultSort: "asc", render:rowData=><Link to={`/students/${rowData._id}`}> {rowData.name} {rowData.surname}</Link> }, 
        { title: "Email",  field: "email", render:rowData=><ClipboardCopy copyText={rowData.email} />   },
        { title: "Contact Number",  field: "contact_number" , sorting:false, emptyValue:()=><em>N/A</em>, render:rowData=><ClipboardCopy copyText={rowData.contact_number} />},
        { title: "Age",  field: "age" },
        { title: "NIN",  field: "nin", render:rowData=><ClipboardCopy copyText={rowData.nin} />   },
        { title: "Passport Expiry",  field: "passport_expiry", type: 'date', dateSetting: { format: 'dd/MM/yyyy'}, cellStyle:{color:"blue"}},
        // { title: "Passport Expiry",  field: "passport_expiry", type:"currency", currencySetting:{currencyCode:"USD",minimumFractionDigits:0}, cellStyle:{color:"blue"}},
    ];

    useEffect(() => {       
        window.scrollTo(0, 0);
    },[])


    //const user = useSelector((state) => state.user.userInfo);
    //const [allStudents,setAllStudents] = useState([]);
    //const [toCards,setToCards] = useState([])

    // const {
    //     data: allStudents,
    //     isLoading,
    //     isSuccess,
    //     isError,
    //     error
    // } = useGetStudentsQuery()

    //const [getStudents] = useGetStudentsQuery()

    const { id } = useAuth()
    
    const allOfThem = useSelector(selectAllStudents)
    //console.log(allOfThem)

    const genderFrom = getGenderSeries(allOfThem)
    const langFrom = getLangSeries(allOfThem)

    const notis = useSelector(selectAllNotifications)
    //console.log(notis)

    let someOfMine = []
    let showWarning = false

    if(notis && notis.length > 0){

        let exit = false;
        notis.map(una => {
            // if(exit){
            //     return
            // }
            if(!una.isProcessed){

                una.owner.map(staff => {
                    if(staff._id == id){

                        showWarning = true;
                        if(someOfMine.length < 4){
                            someOfMine.push(una)
                        }
                        
                        //exit = true
                        //return;
                    }
                })
            }
        })

        if(showWarning){

            toast.warn(messageResources.TOASTY.NOTIS_PENDING ,{
                position:"top-right",
                autoClose: 5000,
                    })

        }            
    }


    // console.log("|-------------> allStudents in HomeAdmin <---------------|")
    // console.log(allStudents)

    // let content;

    // if(isLoading){
    //     <p>Loading....</p>
    // }else if(isSuccess){
        
        // const {ids, entities} = allStudents
        // console.log(ids)
        // console.log(entities)

        // const listContent = ids?.length
        //     ? ids.map(staffId => entities[staffId] )
        //     : null

        // // CHECKING IF LIST IS CORRECT
        // console.log("|-------------> allStudents FOR LIST <---------------|")
        // console.log(listContent)

        let content =   <>
                            <div className="container-fluid">
                                <div className="row">
                                    {someOfMine.length > 0 && someOfMine.map((notification, i) => (                        
                                        <NewNotification data={notification} slots="col-sm-3"/>
                                    ))}
                                </div> 
                            </div>

                            
                            <div className="row">
                                <div className="col-md-6">
                                    <List titleForTable={messageResources.COMMON.STUDENTS} dataForTable={JSON.parse(JSON.stringify(allOfThem))} columnsForTable={columnsForAllStudents} pageSize={5}/>
                                </div>
                                <div className="col-md-6" style={{display: 'flex',  marginBottom: '30px', }}>
                                    <div className="col-md-6" style={{background: '#fff', borderTop: '3px solid #6f824f', borderRadius: '10px', marginRight: '10px',marginBottom: '170px', paddingTop: '10px'}}>
                                        
                                            {/* <AreaChart series={series} /> */}
                                            <PieChart series={genderFrom} title={messageResources.CHART.GENDER} legendPos='bottom'/>
                                    
                                        {/* <div className="col-md-12">
                                            <ColumnChart  series={series} />
                                        </div> */}
                                    </div>
                                    <div className="col-md-6" style={{background: '#fff', borderTop: '3px solid #6f824f', borderRadius: '10px', marginTop: '188px', paddingTop: '10px'}}>
                                    
                                        <PieChart series={langFrom} title={messageResources.CHART.LANGS} legendPos='bottom'/>

                                    </div>
                                </div>
                            </div> 
                            
                            <div className="row">
                                {/* <div className="col-md-6" style={{background: '#fff', borderTop: '3px solid #6f824f', borderRadius: '10px', marginBottom: '-317px', zIndex: 9999}}>
                                    <AreaChart series={series} />
                                </div> */}

                                {allOfThem && allOfThem.map((student) => (
                            
                                    <Card slots="col-md-3" entityId={student.id} />
                            
                                ))} 
                            </div>
                        </>

    //}

    // useEffect(() => {
    //     window.scrollTo(0, 0);

    //     console.log("<><><><><><> ALL STUDENTS ALL <><><><><><>")
    
    //     // NO NEED FOR HEADER?? WE HAVE COOKIE
    //     const getStudentsAndNotis = async () => {
    //         try{
    //             const res = await axios.get("/studentsApi");
    //             // const res = await axios.get(
    //             //     "students",
    //             //     {headers: {
    //             //         Authorization: `Bearer ` + userInRedux.userInfo.accessToken
    //             //     }
        
    //             //     });
    //             setAllStudents(res.data);
    //             //console.log(res.data);

    //             const randomFour = await axios.get(`notifications/random/${user._id}?processed=false`);
    //             setToCards(randomFour.data)
                
    //         }catch(err){
    //             console.log(err);
    //         }
    //     } 
    //     getStudentsAndNotis();
    
    // }, []);


    return (
        
        <>
            <Breadcrumb pageTitle={messageResources.OPS_TITLES.DASHBOARD}/>
            {/* <div className="row">
                {toCards.length > 0 && toCards.map((notification, i) => (
        
                    <Notification data={notification}/>

                ))}
            </div> */}
            {content}
            {/* <div className="row">
                <List dataForTable={allStudents} columnsForTable={columnsForAllStudents} pageSize={5}/>
            </div> 
            <div className="row">
                {allStudents && allStudents.map((student, i) => (
            
                    <Card slots="col-md-3" profile={student} />
            
                ))} 
            </div> */}
        </>                
    )
}

export default HomeAdmin
