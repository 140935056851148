import React from 'react'

const Messages = ({message,type}) => {

    //console.log("|----> Messages COMPONENT Messages <----|")

    return (
        <>

            {message && type === "success" && 
                <div className="alert alert-success" style={{ fontSize: '20px' }} role="alert">
                    <span style={{ display: 'flex' }} >
                        <i className="material-icons" style={{ fontSize: '30px', marginRight: '5px'}} >{  "gps_fixed" }</i>   
                        {message}
                    </span>
                </div>
            }
            {message && type === "error" && 
                <div className="alert alert-danger" style={{ fontSize: '20px' }} role="alert">
                    <span style={{ display: 'flex' }} >
                        <i className="material-icons" style={{ fontSize: '30px', marginRight: '5px'}} >{ "error_outline" }</i>   
                        {message}
                    </span>
                </div>
            }   
            {message && type === "alert" && 
                <div className="alert alert-warning"  style={{ fontSize: '20px' }} role="alert">
                    <span style={{ display: 'flex' }} >
                        <i className="material-icons" style={{ fontSize: '30px', marginRight: '5px'}} >{ "access_time" }</i>   
                        {message}
                    </span>
                </div>
            }
        
        </>
    )
}

export default Messages
