import NewNotification from '../../components/notification/NewNotification'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Messages from '../../components/messages/Messages'
import List from '../../components/list/List'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'; 
import useAuth from '../../hooks/useAuth'
import { selectAllNotifications } from '../../redux/notifications/notificationsApiCalls'
import { messageResources } from '../../assets/util/messageResources'
import { Tab, Nav } from 'react-bootstrap'
// import { useGetNotificationQuery,
//         useAddNotificationMutation,
//         useUpdateNotificationMutation,
//         useDeleteNotificationMutation } from '../../redux/api/apiSlice'


const NotificationsMain = ({tabActive}) => {


   //console.log("|-------> NotificationsMain COMPONENT NotificationsMain <------|")

    const columnsForNotifications = [
        
        { title: "Type",  field: "description"  },
        { title: "Content",  width: "120%", field: "message"},
        { title: "Priority", field: "priority", render:rowData => rowData.priority == 1 ? "High" : rowData.priority == 2 ? "Medium" : "Normal"},
        { title: "Create Date", field: "createdAt", type: 'date', defaultSort: "asc", dateSetting: { format: 'dd MMMM yyyy'}, cellStyle:{color:"blue"}},
        { title: "Actions",  render:rowData=> rowData.type == 1 || rowData.type == 6
                                                ? <Link className="btn btn-outline-success" to={`/students/edit/${rowData.studentId._id}`}> Process </Link> 
                                                : <Link className="btn btn-outline-success" to={`/students/${rowData.studentId._id}`}> Process </Link> },
    ];


    const columnsForProcessedNotificationsByMe = [
        { title: "Student",  field: "studentId.name", render:rowData=> <Link to={`/students/${rowData.studentId._id}`}> {rowData.studentId.name} {rowData.studentId.surname} </Link> },
        { title: "Type",  field: "description"  },
        { title: "Content",  field: "message"},
        { title: "Process Date", field: "dateProcessed", type: 'date', defaultSort: "desc", dateSetting: { format: 'dd MMMM yyyy'}, cellStyle:{color:"blue"}},
        { title: "Days Elapsed",  field: "daysElapsed"},
        
    ];

    const columnsForAllPendingNotifications = [
        { title: "Student",  field: "studentId.name", render:rowData=> <Link to={`/students/${rowData.studentId._id}`}> {rowData.studentId.name} {rowData.studentId.surname} </Link> },
        { title: "Type",  field: "description"  },
        { title: "Content",  field: "message"},
        { title: "Staff Assigned",  field: "ownersAsString", render:rowData=> rowData.ownersAsString ? rowData.ownersAsString : "NO FUNCIONO" },
        { title: "Create Date", field: "createdAt", type: 'date', defaultSort: "asc", dateSetting: { format: 'dd MMMM yyyy'}, cellStyle:{color:"blue"}},
        
    ];

    const columnsForProcessedNotifications = [
        { title: "Student",  field: "studentId.name", render:rowData=> <Link to={`/students/${rowData.studentId._id}`}> {rowData.studentId.name} {rowData.studentId.surname} </Link> },
        { title: "Type",  field: "description"  },
        { title: "Content",  field: "message"},
        { title: "Processed By",  field: "processedBy.name", render:rowData=> `${rowData.processedBy.name} ${rowData.processedBy.surname}` },
        { title: "Process Date", field: "dateProcessed", type: 'date', defaultSort: "desc", dateSetting: { format: 'dd MMMM yyyy'}, cellStyle:{color:"blue"}},
        { title: "Days Elapsed",  field: "daysElapsed"},
        
    ];

    const dispatch = useDispatch()
    //const user = useSelector((state) => state.user.userInfo);
    //const notisToProcess = useSelector((state) => state.notifications.myNotis);
    //console.log("<><><><><><><><><>< NOTIS FROM REDUX <><><><<>><><><><><>><>")

    // I can get all the notis ids from slice nomalization
    // This is useful to past the ids to a component instead the object 
    // and within the component we call const noti = useSelector(state => getNotyById(state, notiId))
    // I could use this in students cards
    // const orderedNotisIds = useSelector(getNotiIds)
    // const misNotis = useSelector(allNotifications)
    // const notisStatus = useSelector(getNotisStatus)
    // const notisError = useSelector(getNotisError)


    // RTK QUERIES FROM APISLICE 
    // const {
    //     data: notifications,
    //     isLoading,
    //     isSuccess,
    //     isError,
    //     error
    // } = useGetNotificationQuery(notificationsList, {
    //          pollingInterval: 15000,
    //          refetchOnFocus: true,
    //          refetchOnMountOrArgChange: true
    // })
    // 
    //const [addNoti] = useAddNotificationMutation()
    //const [updateNoti] = useUpdateNotificationMutation()
    //const [deleteNoti] = useDeleteNotificationMutation()

    const { roles, id } = useAuth()

    const notis = useSelector(selectAllNotifications)
    //console.log(notis)

    let listMyNotis = [];
    let listMyProcessed = [];
    let listAllProcessed = [];
    let listAllPending = [];

    if(notis?.length > 0){

        notis.map(una => {
            
            if(una.isProcessed){
                listAllProcessed.push(una)
                if(una.processedBy?._id == id){
                    listMyProcessed.push(una)
                }
            }else if(una.studentId.isActive){
                
                listAllPending.push(una)
                una.owner.map(staff => {
                    if(staff._id == id){
                        listMyNotis.push(una)
                    }
                })
                
            }
        })

    }

    let contentMyNotis;
    let contentAllProcessed;
    let contentMyProcessed;
    let contentAllPending;

    if(listMyNotis.length > 0){
        contentMyNotis =    <List   
                                dataForTable={JSON.parse(JSON.stringify(listMyNotis))} 
                                columnsForTable={columnsForNotifications}
                                search={false}
                                filter={true}
                                titleForTable={`${messageResources.COMMON.ROWS} ${listMyNotis.length}`}
                                pageSize={listMyNotis.length}
                            />
    }else{
        contentMyNotis = <Messages message={messageResources.NOTIFICATIONS.NO_NOTIS} type="success" />
    }

    if(listMyProcessed.length > 0){

        contentMyProcessed = <List    
                                dataForTable={JSON.parse(JSON.stringify(listMyProcessed))} 
                                columnsForTable={columnsForProcessedNotificationsByMe}
                                search={false}
                                filter={true}
                                titleForTable={`${messageResources.COMMON.ROWS} ${listMyProcessed.length}`}
                                pageSize={5}
                            />
                        
    }else{
        contentMyProcessed = <Messages message={messageResources.NOTIFICATIONS.NO_PROCESSED} type="success" />
    }

    if(listAllProcessed.length > 0){
        contentAllProcessed =    <List   
                                dataForTable={JSON.parse(JSON.stringify(listAllProcessed))} 
                                columnsForTable={columnsForProcessedNotifications}
                                search={false}
                                filter={true}
                                titleForTable={`${messageResources.COMMON.ROWS} ${listAllProcessed.length}`}
                                pageSize={10}
                            />
    }else{
        contentAllProcessed =  <Messages message={messageResources.NOTIFICATIONS.NO_PROCESSED} type="success" />
    }


    if(listAllPending.length > 0){
        contentAllPending =    <List   
                                dataForTable={JSON.parse(JSON.stringify(listAllPending))} 
                                columnsForTable={columnsForAllPendingNotifications}
                                search={false}
                                filter={true}
                                titleForTable={`${messageResources.COMMON.ROWS} ${listAllPending.length}`}
                                pageSize={10}
                            />
    }else{
        contentAllPending =  <Messages message={messageResources.NOTIFICATIONS.NO_PENDING} type="success" />
    }


    return (

        <>
            <Breadcrumb pageTitle={messageResources.OPS_TITLES.NOTIFICATIONS} />
            <div className="row">

            <Tab.Container defaultActiveKey="pending"  className="mb-3">

                <Nav variant="tabs">
                    {/* <Row> */}
                        <Nav.Item>
                            <Nav.Link eventKey="pending">{messageResources.NOTIFICATIONS.PENDING}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="processed">{messageResources.NOTIFICATIONS.PROCESSED}</Nav.Link>
                        </Nav.Item>
                        {roles.includes(2) && (
                            <>
                                <Nav.Item>
                                    <Nav.Link eventKey="allProcessed">{messageResources.NOTIFICATIONS.ALLPROCESSED}</Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="allPending">{messageResources.NOTIFICATIONS.ALLPENDING}</Nav.Link>
                                </Nav.Item>
                            </> 
                        )}
                    {/* </Row> */} 
                </Nav>

                <Tab.Content>

                    <Tab.Pane eventKey="pending" title={messageResources.NOTIFICATIONS.PENDING}>
                        
                        <div className="row">
                            <div className="col-md-9">
                                {contentMyNotis}
                            </div>
                            <div className="col-md-3" >
                                <div style={{borderTop: '3px solid #6f824f', borderRadius: '10px'}}>
                                    <div className="row">
                                        {listMyNotis.length > 0 && listMyNotis.map((notification, i) => (
                                            <NewNotification data={notification} slots="col-sm-12"/>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </Tab.Pane>

                    <Tab.Pane eventKey="processed" title={messageResources.NOTIFICATIONS.PROCESSED}>

                        {contentMyProcessed}

                    </Tab.Pane>

                    {roles.includes(2) && (
                        <>
                            <Tab.Pane eventKey="allProcessed" title={messageResources.NOTIFICATIONS.ALLPROCESSED}>
                            
                                {contentAllProcessed}
                            
                            </Tab.Pane>

                            <Tab.Pane eventKey="allPending" title={messageResources.NOTIFICATIONS.ALLPENDING}>
                                
                                { contentAllPending }

                            </Tab.Pane>
                        </>
                    )}

                </Tab.Content>
            </Tab.Container>        
            
            </div>
        </>   

    )
}

export default NotificationsMain