import React from 'react'

const Footer = () => {
    return (
    
		<div className="page-footer">
            <div className="page-footer-inner"> 2025 &copy; Seroots &#38; Shoots by &nbsp;
                <a href="mailto:eiranovahrt@gmail.com" target="_top" style={{color: "#fff"}}>Manuel Lopez</a>
            </div>
            <div className="scroll-to-top">
                <i className="icon-arrow-up"></i>
            </div>
        </div>

    )
}

export default Footer
