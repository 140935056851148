import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from "../api/apiSlice"

const notificationsAdapter = createEntityAdapter({})

const initialState = notificationsAdapter.getInitialState()

export const notificationsApiCalls = apiSlice.injectEndpoints({

    endpoints: builder => ({
        getNotifications: builder.query({
            query: () => '/notificationsApi',
            transformResponse: responseData => {

                // ONLY LOAD NOTIS FROM ACTIVE STUDENTS
                const loadedNotis = []
                responseData.map(noti => {
                    // ESSENTIAL TO USE NORMALIZATION AND MONGO DB
                    //console.log("XXXXXXXXXX|--> getNotifications SLICEEEEEEEE getNotifications <--|XXXXXXXXX")
                    noti.id = noti._id

                    if(noti.owner.length > 0){
                        let ownerString = "";
                        noti.owner.map((staff) => {
                            ownerString += `${staff.name} ${staff.surname} , `
                        })
                        noti.ownersAsString = ownerString.slice(0,-3)
                    }
                    if(noti.studentId.isActive) {loadedNotis.push(noti)}
                })
                return notificationsAdapter.setAll(initialState, loadedNotis)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids){
                    return [
                        { type: 'Notifications', id: 'LISTNOTIS'},
                        ...result.ids.map(id => ({ type: 'Notifications', id}))
                    ]
                } else return [{ type: 'Notifications', id: 'LISTNOTIS'}]
                
            }
        }),
        // getMyNotifications: builder.query({
        //     query: (idStaff) => `notifications/${idStaff}`,
        //     transformResponse: responseData => {
        //         const loadedNotis = responseData.map(noti => {
        //             // ESSENTIAL TO USE NORMALIZATION AND MONGO DB
        //             noti.id = noti._id
        //             return noti
        //         })
        //         return notificationsAdapter.setAll(initialState, loadedNotis)
        //     },
        //     providesTags: (result, error, arg) => [
        //         { type: 'Notifications', id: 'LIST'},
        //         ...result.ids.map(id => ({ type: 'Notifications', id}))
        //     ]
        // }),
        addNotification: builder.mutation({
            query: notiData => ({
                url: '/notificationsApi/add',
                method: 'POST',
                body: {
                    ...notiData
                }
            }),
            // validateStatus: (response, result) => {
            //     console.log("|----> VALIDATING addNotification RESPONSE <---")
            //     console.log(response.status)
            //     return response.status === 200 && !result.error
            // },
            invalidatesTags: (result, error, arg) => [
                { type: 'Notifications', id: 'LISTNOTIS'}
            ]
        }),
        updateNotification: builder.mutation({
            query: notiToUpdate => ({
                url: `/notificationsApi/${notiToUpdate.idFor}`,
                method: 'PUT',
                body: notiToUpdate
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Notifications', id: arg.idFor }
            ]    
        })
    })
})

export const {
    useGetNotificationsQuery,
    useGetMyNotificationsQuery,
    useAddNotificationMutation,
    useUpdateNotificationMutation
} = notificationsApiCalls



// returns the query result object
export const selectNotificationsResult = notificationsApiCalls.endpoints.getNotifications.select()

// creates memoized selector
// used below to create selectors
const selectNotificationsData = createSelector(
    selectNotificationsResult,
    notificationsResult => notificationsResult.data // normalized state object ids & entities
)

export const {
    selectAll: selectAllNotifications,
    selectById: selectNotificationById,
    selectIds: selectNotificationsIds
} = notificationsAdapter.getSelectors(state => selectNotificationsData(state) ?? initialState)





//RTK Query replaces axios and pulls that code out of the 
//component logic 
// export const notificationsApiCalls = createApi({
//     // this is the default value so can be removed
//     reducerPath: 'api',
//     baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:8800/serruts/'}),
//     // the results of RTK queries get cached so we need to invalidate the previous 
//     // query results in order to see the updated results from the below mutations
//     // so we assign a tag to the cache and we let it know what mutations will invalidate that chache
//     // and then it'll automatically fetch the updated data 
//     tagTypes: ['Notifications'],
//     // here we define methods to interact with the api
//     endpoints: (builder) => ({
//         getNotification: builder.query({
//             query: () => '/notifications',
//             // we can manipulate the response in this case sorting by id
//             transformResponse: res => res.sort((a,b) => b.id - a.id),
//             // giving a tag to this query to be referenced in the mutations to invalidate
//             // its cache when executing the mutations below
//             providesTags: ['Notifications']
//         }),
//         // herer we use mutation vause we are changing the data 
//         addNotification: builder.mutation({
//             query: (notification) => ({
//                 url: '/notifications/add',
//                 method: 'POST',
//                 body: notification
//             }),
//             // invalidate cache from providesTags: ['Notifications']
//             // when executing this mutation
//             invalidatesTags: ['Notifications']
//         }),
//         updateNotification: builder.mutation({
//             query: (notification) => ({
//                 url: `/notifications/${notification.id}`,
//                 // PATCH WHEN YOU EDIT PART OF THE ENTITY
//                 // AND PUT WHEN YOU EDIT ALL OF IT
//                 // YOU CAN USE EITHER OF THEM
//                 method: 'PUT',
//                 body: notification
//             }),
//             invalidatesTags: ['Notifications']
//         }),
//         deleteNotification: builder.mutation({
//             query: ({ idNoti }) => ({
//                 url: `/notifications/${idNoti}`,
//                 method: 'DELETE',
//                 body: idNoti
//             }),
//             invalidatesTags: ['Notifications']
//         })
//     })
// })


//we can create custom hooks based on the methods created above
// export const {
//     useGetNotificationQuery,
//     useAddNotificationMutation,
//     useUpdateNotificationMutation,
//     useDeleteNotificationMutation
// } = notificationsApiCalls

















// export const totalNotifications = async (idUser,dispatch) => {

//     //dispatch(loginStart());
//     // const userId = user.email
//     // const password = user.password
//     console.log("<><><><><><> TOTAL NOTIFICATIONS IN REDUX CALLS <><><><><><>")
//     console.log(idUser)

//     try {

//         const notis = await axios.get(`/notifications/toProcess/${idUser}`);

//         console.log(`SIZE OF NOTIS ${notis.data.length}`)
//         dispatch(notificationsTotal(notis.data.length)); 

//         // let notisToShow = []
//         // console.log("<><><><><><> BEFORE MAP <><><><><><>")
//         // if(notis.data.length > 0){

//         //     notis.data.map((noti) => {
//         //         let notiToList = {}
//         //         notiToList.type = noti.type.id
//         //         notiToList.desc = noti.type.desc
//         //         notiToList.message = noti.message
//         //         notiToList.priority = noti.priority
//         //         notiToList.studentId = noti.studentId
//         //         notisToShow.push(notiToList)
//         //     })

//         // }
//         // console.log("<><><><><><> AFTER MAP <><><><><><>")
//         // console.log(notisToShow)

//         console.log("<><><><><><> NOTIFICATIONS REDUX RESPONSE <><><><><><>")
//         console.log(notis)

//         //dispatch(notificationsToProcess(notis))

//         console.log("<><><><><><> NOTIFICATIONS REDUX LIST <><><><><><>")
//         console.log(notis.data)

//         return notis.data.length

//     }catch(err){
//         console.log(err)
//         dispatch(notificationsError());
//     }

// }

