
import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit"
import axios from "axios"

// NORMALIZED STATE
// Recomended, no duplication of data and creates an ID lookup 
// createEntityAdapter
const notificationAdapter = createEntityAdapter({
    // this loginc was in the component but we can abstract it here 
    sortComparer: (a,b) => b.createDate.localeCompare(a.createDate)
})

// instead having the normal initial state with the notifications[] as below
// we normalize initial state 
// const initialState = {
//     notifications: [],
//     status:  'idle', // idle | loading | succeeded | failed
//     error: null,
    
// }

// initial state normalized with notificationAdapter
const initialState = notificationAdapter.getInitialState({
    status:  'idle', // idle | loading | succeeded | failed
    error: null,
})



// redux does averything syncronously so any async operation has to happen outside the store
// and this is where redux middleware comes. the most popular is redux thunk
// thunks are recommended as the standrad approach to write aync logic with redux
// thunk is apiece of code that does some delayed work
// prefix for the generated action type 'notifis/fetchToProcess'
export const fetchMyNotisToProcess = createAsyncThunk('notis/fetchMyNotisToProcess', async (userId) => {
        // This return a Promise. no try catch as we handle Promise pending / fulfilled / rejected
        const response = await axios.get(`/notifications/toProcess/${userId}`);
        return response.data
})


export const addNewNotification = createAsyncThunk('notis/addNewNotification', async (notification) => {
    // MAYBE TRY CATCH IS NECESSARY
    try{

    }catch(err){

    }
})



export const updateNotification = createAsyncThunk('notis/updateNotification', async (notification) => {
    const { notiId } = notification
    try{
        const response = axios.put(`/notifications/${notiId}`,notification)
        // if(response?.status !== 200) return `${response?.status}: ${response?.statusText}`;
        return response.data
    }catch(err){
        return err.message
    }
})




export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    // initialState: {
    //     total:  0,
    //     error: false,
    // },
    reducers: {
        
        // FIRST SOLUTION: CUSTOM REDUCERS
        // messageStart: (state) => {
        //     state.total = ""
        // },
        notificationsTotal: (state,action) => {
            state.total = action.payload
        },
        notificationsToProcess: (state,action) => {
            state.notifications = action.payload
        },
        // FOR THE NEW REDUCERS TO WORKPORPERLY WE HAVE TO CHANGE THE 
        // INITIAL STATE TO AN ARRAY OF NOTIFICATIONS
        // TEST THIS ONE WHEN ADDING NEW NOTI
        addNotification: (state,action) => {
            // before normalizing
            //state.notifications.push(action.payload)
            // after normalizing
            notificationAdapter.addOne(state, action.payload)
        },
        // This reducers is divided in two parts reducer and prepare
        // reducer dscribes the action and prepare does a formatting
        // of the payload to be taken for the reducer 
        // we will call this reducer like this: dispatch(addNotiFormatted(notification))
        addNotiFormatted: {
            reducer(state, action){
                state.notifications.push(action.payload)
            },
            prepare({notification}) {
                return {
                    payload: {

                    }
                }
            }

        },
        // Example of reducer wih logic in it
        // this would be called like 
        // dispatch(notificationProcessed({ notiId: id }))
        notificationProcessed(state, action) {
            const { notiId } = action.payload
            // not normalized retrieving thisNotification
            //const thisNotification = state.notifications.find(noti => noti.id == notiId)
            // retrieving thisNotification once normalized
            const thisNotification = state.entities[notiId]
            if(thisNotification){
                // thisNotification is retrieved from the redux state
                // redux state is not mutable so therefore changing thisNotification
                // can be only done from the createSlice 
                thisNotification.isProcessed = true
            }

        },
        notificationsError: (state,action) => {
            state.error = action.payload;
            state.total = 0;
        },

    },
    // builder parameter let us define additional case reducers 
    // that run in response to actions defined autside of the slice  
    // such as the thunk called fetchMyNotisToProcess
    extraReducers(builder) {
        // this 'fetchMyNotisToProcess.pending' represents the different status 
        // that the Promise returned by the thunk can have
        builder
            .addCase(fetchMyNotisToProcess.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchMyNotisToProcess.fulfilled, (state, action) => {
                state.status = 'succeeded'
                const misNotis = action.payload.map(noti => {
                    // here I can even give shape to objects
                    // can be only done from the createSlice 
                    noti.type = 3
                    return noti
                })
                // before normalizing
                //state.notifications = action.payload
                // after normalizing
                notificationAdapter.upsertMany(state, misNotis)
            })
            .addCase(fetchMyNotisToProcess.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(updateNotification.fulfilled, (state, action) => {
                if(!action.payload?.id){
                    console.log('|-----> [notificationSlice] ----> [updateNotification] ---> NO ID PROVIDED <-----|')
                    console.log(action.payload)
                    return
                }
                //const { notiId } = action.payload
                // before normalizing
                // filter out the previous noti
                //const notis = state.notifications.filter(noti => noti.id !== notiId)
                // update state with all the previous notis and adding the updated one
                // state.notifications = [...notis, action.payload]
                // after normalizing
                notificationAdapter.upsertOne(state, action.payload)
            })
    }

});

// SELECTOR instead of getting it like useSelector(state.notifications.notifications)
// we get it like useSelector(allNotifications)
// replaced after normalization export const allNotifications = (state) => state.notifications.notifications;
export const getNotisStatus = (state) => state.notifications.status;
export const getNotisError = (state) => state.notifications.error;

// SELECTOR to get noti per id 
// HOW TO CALL IT FROM COMPONENT
// after importing it
// const noti = useSelector((state) => getNotyById(state, notiId))
// REPLACED BELOW WITH NORMALIZATION
//export const getNotyById = (state, notiId) => state.notifications.notifications.find(noti => noti.id == notiId)


// wirh nomalization we can remove some selectors cause they come with getSelectors()
// getSelectors create these selectors and we rename them using destructuring
export const {
    selectAll: allNotifications,
    selectById: getNotyById,
    selectIds: getNotiIds
} = notificationAdapter.getSelectors(state => state.notifications)


export const { notificationsTotal, notificationsError, notificationsToProcess } = notificationsSlice.actions;

export default notificationsSlice.reducer;