import React, {memo} from 'react'

const Breadcrumb = ({pageTitle, buttonsInfo}) => {

    //const navigate = useNavigate();

    return (
        // <div className="page-bar">
        //     <div className="page-title-breadcrumb">
        //         <div className=" pull-left">
        //             <div className="page-title">{pageTitle}</div>
        //         </div>
                
        //         <ol className="breadcrumb page-breadcrumb-button pull-right">
        //             {buttonsInfo && buttonsInfo.length > 0 && buttonsInfo.map(buttonData => (
                        
        //                 <li>&nbsp;
        //                     <Link to={buttonData.linkTo} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
        //                         {buttonData.title}
        //                     </Link>&nbsp;
        //                 </li>
        //             ))}
        //         </ol>

        //         {/* <ol className="breadcrumb page-breadcrumb pull-right">
        //             <li><i className="fa fa-home"></i>&nbsp;<a className="parent-item"
        //                     href="index.html">Home</a>&nbsp;<i className="fa fa-angle-right"></i>
        //             </li>
        //             <li><a className="parent-item" href="">Data Tables</a>&nbsp;<i
        //                     className="fa fa-angle-right"></i>
        //             </li>
        //             <li className="active">Advance Tables</li>
        //         </ol> */}
        //     </div>
        // </div>

        <div className="box shadow">
            <div className="circle"></div>
            <p>{pageTitle}</p>
        </div>
    )
}

export default memo(Breadcrumb)
